import React from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Checkbox,
    Link,
    Text,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/core';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useRegisterMutation } from '../mutations/registerMutation';

const RegisterForm = ({ loading }) => {
    const [show, setShow] = React.useState(false);
    const handlePwdClick = () => setShow(!show);

    const { handleSubmit, errors, register, setValue } = useForm();
    const [registerMutation, registerMutationResults] = useRegisterMutation(errors);
    const disableForm = registerMutationResults.loading || loading;
    const onSubmit = (values, ...errors) =>
        registerMutation(
            values.email,
            values.firstName,
            values.lastName,
            values.password,
            values.phone,
            values.profession,
            values.company,
            values.certifications,
            values.personalInfoAgreement,
            values.acceptedPrivacyPolicy,
            errors.password,
            errors.email,
        );
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isRequired isInvalid={errors.firstName} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='firstName'>Nome</FormLabel>
                    <Input
                        name='firstName'
                        id='firstName'
                        type='text'
                        placeholder='John'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            maxLength: { value: 50, message: 'max 50 character' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.firstName?.message || errors.firstName}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={errors.lastName} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='lastName'>Cognome</FormLabel>
                    <Input
                        name='lastName'
                        id='lastName'
                        placeholder='Doe'
                        type='text'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            maxLength: { value: 50, message: 'max 50 character' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.lastName?.message || errors.lastName}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.email} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input
                        name='email'
                        id='email'
                        placeholder='example@email.com'
                        type='email'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    />
                    <FormErrorMessage>{errors.email?.message || errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.password} mb={[4, 8, 8]}>
                    <FormLabel htmlFor='password'>Password</FormLabel>
                    <InputGroup size='lg'>
                        <Input
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            name='password'
                            id='password'
                            placeholder='Password'
                            ref={register({
                                required: { value: true, message: 'Questo campo è obbligatorio' },
                                minLength: {
                                    value: 4,
                                    message: 'password deve contenere almeno 4 caratteri',
                                },
                            })}
                        />
                        <InputRightElement>
                            {show ? (
                                <ViewOffIcon
                                    color='brand.500'
                                    onClick={handlePwdClick}
                                    style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <ViewIcon
                                    color='grey.200'
                                    onClick={handlePwdClick}
                                    style={{ cursor: 'pointer' }}
                                />
                            )}
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.password?.message || errors.password}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.phone} mb='6'>
                    <FormLabel htmlFor='phone'>Cellulare</FormLabel>
                    <Input
                        name='phone'
                        id='phone'
                        type='tel'
                        placeholder='Per favorire la partecipazione'
                        ref={register({
                            minLength: { value: 5, message: 'min 5 character' },
                        })}
                    />
                    <FormErrorMessage>{errors.phone?.message || errors.phone}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.profession} mb='6'>
                    <FormLabel htmlFor='profession'>Professione</FormLabel>
                    <Input
                        name='profession'
                        id='profession'
                        type='text'
                        placeholder='La tua risposta'
                        ref={register({
                            minLength: { value: 5, message: 'min 5 character' },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.profession?.message || errors.profession}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.company} mb='6'>
                    <FormLabel htmlFor='company'>Azienda</FormLabel>
                    <Input
                        name='company'
                        id='company'
                        type='text'
                        placeholder='La tua risposta'
                        ref={register({
                            minLength: { value: 5, message: 'min 5 character' },
                        })}
                    />
                    <FormErrorMessage>{errors.company?.message || errors.company}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.certifications} mb='6'>
                    <FormLabel>Certificazioni</FormLabel>
                    <Checkbox
                        name='certifications'
                        onChange={e => setValue('certifications', e.target.checked)}
                        ref={register}
                    >
                        Richiedo attestato di partecipazione
                    </Checkbox>
                </FormControl>
                {/* <input type='checkbox' name='sertifications' ref={register} /> */}
                <FormControl isRequired isInvalid={errors.personalInfoAgreement} mb='6'>
                    <FormLabel>Trattamento dei dati personali</FormLabel>
                    <Checkbox
                        defaultIsChecked
                        name='personalInfoAgreement'
                        onChange={e => setValue('personalInfoAgreement', e.target.checked)}
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    >
                        Acconsento al trattamento dei dati personali
                    </Checkbox>
                    <FormErrorMessage>
                        {errors.personalInfoAgreement?.message || errors.personalInfoAgreement}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={errors.acceptedPrivacyPolicy} mb='6'>
                    <FormLabel htmlFor='name'>Privacy</FormLabel>
                    <Checkbox
                        defaultIsChecked
                        name='acceptedPrivacyPolicy'
                        onChange={e => setValue('acceptedPrivacyPolicy', e.target.checked)}
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    >
                        <Text>Ho preso visione delle norme sulla privacy</Text>
                        <Text>
                            (
                            <Link
                                fontWeight='500'
                                color='brand.500'
                                as={RouterLink}
                                to='/termini-condizioni'
                            >
                                'Termini'
                            </Link>
                            ,{' '}
                            <Link
                                fontWeight='500'
                                color='brand.500'
                                as={RouterLink}
                                to='/privacy-policy'
                            >
                                'Privacy Policy'
                            </Link>
                            {/* ,{' '}
                            <Link
                                fontWeight='500'
                                color='brand.500'
                                href='https://zoom.us/privacy'
                                ml='1'
                                isExternal
                            >
                                zoom.us/privacy
                            </Link> */}
                            )
                        </Text>
                    </Checkbox>
                    <FormErrorMessage>
                        {errors.acceptedPrivacyPolicy?.message || errors.acceptedPrivacyPolicy}
                    </FormErrorMessage>
                </FormControl>

                <Button
                    type='submit'
                    size='lg'
                    colorScheme='brand'
                    isFullWidth
                    disabled={disableForm}
                >
                    Registrati
                </Button>
            </form>
        </>
    );
};

export default RegisterForm;
